<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn
            fab
            dark
            small
            color="indigo"
            v-on="{ ...on, ...tooltipOn }"
            style="text-align: right"
          >
            <v-icon dark>published_with_changes</v-icon>
          </v-btn>
        </template>
        <span>College Transfer (Probable Data)</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title style="background-color: #3f51b5; color: #fff">
        <span class="headline">College Transfer (Probable Data)</span>
        <v-spacer></v-spacer>
        <v-btn color="red" fab small dark @click="clearData()"
          ><v-icon>close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <v-autocomplete
              class="ma-2"
              v-model="searchParam.college_code"
              :item-text="(item) => `${item.college_code}-${item.college_name}`"
              item-value="college_code"
              :items="getColleges"
              menu-props="auto"
              label="Select College"
              prepend-icon="subject"
              clearable
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-tile-content v-text="data.item"></v-list-tile-content>
                </template>
                <template v-else>
                  <v-list-tile-content>
                    <v-list-tile-title
                      v-html="
                        `${data.item.college_code}-${data.item.college_name}`
                      "
                    ></v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </template>
            </v-autocomplete>
          </v-flex>
        </v-layout>
        <small style="color: red; font-size: 13px"
          >Select <strong>College</strong> Where to Transfer</small
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click="transferCollege()">submits</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    x: () => {}
  },
  data() {
    return {
      dialog: false,
      searchParam: {},
    };
  },
  created: {},
  computed: {
    getColleges() {
      return this.$store.getters.getSearchParamCollege;
    },
  },
  methods: {
    transferCollege(){
        let peram = {};
        peram.form_fill_up_id = this.item.id
        peram.college_code = this.searchParam.college_code
        console.log("Tanvir! This is Transfer Col", peram);
        this.$store.dispatch("changeCollegeData", peram)
        .then((resp)=>{
            this.x();
            this.dialog = true;
        });
    },
    clearData() {
      this.searchParam.college_code = "";
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.custom-multiline-text-field .v-input__control {
  height: 160px; /* Adjust height as needed */
}

.custom-multiline-text-field .v-input__slot {
  white-space: pre-wrap; /* Maintains line breaks in the text */
}
</style>
