<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        fab
        small
        dark
        v-on="on"
        style="border-radius: 100px"
        @click="getPaymentInfo(item.id)"
        ><v-icon>info</v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title style="background-color: #3f51b5; color: #fff">
        <span class="headline">Payment List</span>
        <v-spacer></v-spacer>
        <v-btn color="red" fab small dark @click="dialog = false"
          ><v-icon>close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 style="margin-bottom: 10px">
            <table style="width: 100%">
              <tbody>
                <tr>
                  <td style="padding: 5px">Regstration No</td>
                  <td style="padding-left: 5px">{{ item.reg_no }}</td>
                </tr>
                <tr>
                  <td style="padding: 5px">Name</td>
                  <td style="padding-left: 5px">{{ item.std_name }}</td>
                </tr>
                <tr>
                  <td style="padding: 5px">Subject</td>
                  <td style="padding-left: 5px">
                    {{ item.subject_code }} - {{ item.subject_name }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px">College</td>
                  <td style="padding-left: 5px">
                    {{ item.college_code }} - {{ item.college.college_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-flex>

          <v-flex xs12 sm12 md12>
            <v-data-table
              v-if="
                getPaymentInfoSummary &&
                getPaymentInfoSummary.data &&
                getPaymentInfoSummary.data.form_fill_up_fee
              "
              :headers="headers"
              :items="getPaymentInfoSummary.data.form_fill_up_fee"
              hide-actions
              class="elevation-1"
            >
              <template v-slot:items="props">
                <td>{{ props.index + 1 }}</td>
                <td class="text-xs-left">
                  {{ props.item.exm_master_fee.fee_title }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.exm_master_fee.fee_type }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.exm_master_fee.amount }}
                </td>
                <td class="text-xs-left">
                  <v-btn
                    fab
                    dark
                    small
                    color="red"
                    style="margin-left: 20px; border-radius: 100px"
                    @click="
                      cancleFFPayment(
                        getPaymentInfoSummary.data.id,
                        getPaymentInfoSummary.data.std_id,
                        props.item.id
                      )
                    "
                  >
                    <v-icon>block</v-icon>
                  </v-btn>
                </td>
              </template>
              <template v-slot:footer>
                <td :colspan="headers.length">
                  <strong style="color: red"
                    >*NU Fee: {{ getPaymentInfoSummary.data.nu_fee }}</strong
                  >
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "SL",
          align: "left",
          sortable: false,
        },
        { text: "Title", sortable: false },
        { text: "Fee Type", sortable: false },
        { text: "Fee Amount", sortable: false },
        { text: "Action", sortable: false },
      ],
    };
  },
  computed: {
    getPaymentInfoSummary() {
      return this.$store.getters.getStudentFFPaymentSummary;
    },
  },
  methods: {
    getPaymentInfo(form_fill_up_id) {
      let peram = {};
      peram.form_fill_up_id = form_fill_up_id;
      this.loading = true;
      console.log("Tanvir! this is payment", peram);
      this.$store.dispatch("getFormFillupPaymentInfo", peram).then((data) => {
        console.log("Tanvir! called", data);
      });
    },

    cancleFFPayment(form_fill_up_id, std_id, ff_fee_id) {
      let peram = {};
      peram.form_fill_up_id = form_fill_up_id;
      peram.std_id = std_id;
      peram.ff_fee_id = ff_fee_id;
      this.loading = true;
      console.log("Tanvir! This is payment cancle data", peram);
      this.$store.dispatch("cancleFFPaymenteExaminee", peram).then((data) => {
        console.log("Tanvir! called", data);
        let peram = {};
        peram.form_fill_up_id = form_fill_up_id;
        this.loading = true;
        console.log("Tanvir! this is payment", peram);
        this.$store.dispatch("getFormFillupPaymentInfo", peram).then((data) => {
          console.log("Tanvir! called", data);
        });
      });
    },
  },
};
</script>
<style lang=""></style>
