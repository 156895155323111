var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"border-radius":"100px"},attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.getPaymentInfo(_vm.item.id)}}},on),[_c('v-icon',[_vm._v("info")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#3f51b5","color":"#fff"}},[_c('span',{staticClass:"headline"},[_vm._v("Payment List")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","fab":"","small":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"margin-bottom":"10px"},attrs:{"xs12":"","sm12":"","md12":""}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"padding":"5px"}},[_vm._v("Regstration No")]),_c('td',{staticStyle:{"padding-left":"5px"}},[_vm._v(_vm._s(_vm.item.reg_no))])]),_c('tr',[_c('td',{staticStyle:{"padding":"5px"}},[_vm._v("Name")]),_c('td',{staticStyle:{"padding-left":"5px"}},[_vm._v(_vm._s(_vm.item.std_name))])]),_c('tr',[_c('td',{staticStyle:{"padding":"5px"}},[_vm._v("Subject")]),_c('td',{staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(_vm.item.subject_code)+" - "+_vm._s(_vm.item.subject_name)+" ")])]),_c('tr',[_c('td',{staticStyle:{"padding":"5px"}},[_vm._v("College")]),_c('td',{staticStyle:{"padding-left":"5px"}},[_vm._v(" "+_vm._s(_vm.item.college_code)+" - "+_vm._s(_vm.item.college.college_name)+" ")])])])])]),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[(
              _vm.getPaymentInfoSummary &&
              _vm.getPaymentInfoSummary.data &&
              _vm.getPaymentInfoSummary.data.form_fill_up_fee
            )?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getPaymentInfoSummary.data.form_fill_up_fee,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(props.item.exm_master_fee.fee_title)+" ")]),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(props.item.exm_master_fee.fee_type)+" ")]),_c('td',{staticClass:"text-xs-left"},[_vm._v(" "+_vm._s(props.item.exm_master_fee.amount)+" ")]),_c('td',{staticClass:"text-xs-left"},[_c('v-btn',{staticStyle:{"margin-left":"20px","border-radius":"100px"},attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.cancleFFPayment(
                      _vm.getPaymentInfoSummary.data.id,
                      _vm.getPaymentInfoSummary.data.std_id,
                      props.item.id
                    )}}},[_c('v-icon',[_vm._v("block")])],1)],1)]}},{key:"footer",fn:function(){return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('strong',{staticStyle:{"color":"red"}},[_vm._v("*NU Fee: "+_vm._s(_vm.getPaymentInfoSummary.data.nu_fee))])])]},proxy:true}],null,false,2695517675)}):_vm._e()],1)],1)],1),_c('v-card-actions')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }